.terms-and-condition{
    .heading-xs{
        position: relative;
        &::after{
            content: "";
            position: absolute;
            left: -30px;
            top: 5px;
            width: 20px;
            height: 20px;
            background-image: url("../../../public/assets/img/product/drbwc_images/star.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}