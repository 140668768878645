.magnify-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; // Ensure zoom preview is positioned correctly

  img {
    cursor: zoom-in;
    // max-width: 450px !important;
    max-height: 450px;
  }

  @media #{$xs-layout} {
    img {
      // width: 300px !important;
      height: 350px;
    }
  }
}

.zoom-preview {
  height: 500px; // Fixed height for preview
  width: 500px;  // Fixed width for preview
  border: 1px solid #6e6e6e;
  background-repeat: no-repeat;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 20px;
  z-index: 100;
  display: none; // Initially hidden

  @media only screen and (max-width: 910px) {
    display: none !important;
  }
}

.product-large-image-wrapper{
  .swiper{
    overflow: unset !important;
  }
}