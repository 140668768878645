/*------- 14. Brand logo style  -------*/

.brand-logo-area{
  background-color: #f6f6f6;

  .brand-logo-active,
  .brand-logo-active-2 {
    &.owl-carousel {
      .owl-item {
        img {
          display: inline-block;
          width: auto;
        }
      }
    }
    .single-brand-logo,
    .single-brand-logo2 {
      text-align: center;
      height: 150px;
      width: 150px;
      margin: auto;
      background: $white;
      box-shadow: 0 0 5px rgba(0,0,0,.1);
      img {
        transition: all 0.5s ease 0s;
      }
      &:hover img {
        filter: grayscale(0%);
      }
    }
  }
  
  .brand-logo-wrap {
    padding: 100px 90px;
    @media #{$xs-layout} {
      padding: 50px 20px;
    }
  }
}
