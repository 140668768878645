// Font Family
$poppins: "Poppins", sans-serif;
$abrilfatface: "Abril Fatface", cursive;
$cormorantgaramond: "Cormorant Garamond";
$greatvibes: "Great Vibes", cursive;
$lobster: "Lobster", cursive;
$josefin: "Josefin Sans", sans-serif;

// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #909090;
$red: #f6f7f8;
$green: #f6f7f8;
$cyan: #007185;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: #cda646;
$theme-color-dark: #947831;
$theme-brown: #72442F;
$theme-dark-brown: #5f3826;
$theme-black: #231f20;
$theme-dark-black: #070606;
$theme-red: #b12704;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";

// font Sizes
$heading-3xl:50px;
$heading-xxl:45px;
$heading-xl:40px;
$heading-lg:35px;
$heading-md:30px;
$heading-sm:25px;
$heading-xs:20px;
$heading-xxs:15px;

$desc-3xl:24px;
$desc-xxl:22px;
$desc-xl:20px;
$desc-lg:18px;
$desc-md:16px;
$desc-sm:14px;
$desc-xs:13px;
$desc-xxs:12px;

$lh-3xl:40px;
$lh-xxl:35px;
$lh-xl:30px;
$lh-lg:25px;
$lh-md:20px;
$lh-sm:15px;
$lh-xs:10px;
