@import "~bootstrap/scss/bootstrap";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.cdnfonts.com/css/romelio-sans');

@import "variables";
@import "helper";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";
@import "video-slider";
@import "whydrbwc";
@import "gallery";
@import "terms-and-condition";
@import "masterv4thermalbed";
@import "image-magnifier";
@import "corporate-gifts";
@import "common";
