.gallery{
    .gallery-box{
        background: #fff;
        box-shadow: 0px 3px 11px rgba(0,0,0,.2);
        border-radius: 0 60px 0 60px;
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .gallery-img{
            width: 80%;
            height: 80%;
            object-fit: contain;
            object-position: center;
        }
    }
}